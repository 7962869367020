// App main
const main = async() => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    
    // load the vue waypoint
    const { default: VueWaypoint } = await import(/* webpackChunkName: "vuewaypoint" */ 'vue-waypoint');
    Vue.use(VueWaypoint)

    const { Hooper, Slide, Pagination } = await import(/* webpackChunkName: "hooper" */ 'hooper');

    // Create our vue instance
    const vm = new Vue({
        el: "#block-container",
        components: {
            'modal': () => import(/* webpackChunkName: "modal" */ '@/vue/Modal.vue'),
            'logo-swapper': () => import(/* webpackChunkName: "logoSwapper" */ '@/vue/LogoSwapper.vue'),
            'logo-swapper-craft': () => import(/* webpackChunkName: "logoSwapper" */ '@/vue/LogoSwapperCraft.vue'),
            Hooper,
            Slide,
            HooperPagination: Pagination
        },
        data: function() {
            return {
                modalLink : '',
                currentFaq: false,
                currentVideoEmbed: '',
                intersectionOptions: {
                    threshold: [0]
                }
            };
        },

        methods: {
            onWaypoint({ el, going, direction }) {
                if( this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')

                    // table on contents active item highlighting
                    if( el.dataset.toc && document.querySelector('#' + el.dataset.toc) ) {

                        let tocItem = document.querySelector('#' + el.dataset.toc)
                        let tocLinks = document.querySelectorAll('a.tocLink');
                        tocLinks.forEach(function(item){
                            item.classList.remove('active')
                        });

                        tocItem.classList.add( 'active' );
                    }
                }
            },

            loadAudio(element) {
                document.querySelector('#listenlink-' + element).classList.add('hidden')
                document.querySelector('#audioplayer-' + element).classList.add('open')
                document.querySelector('#audioplayer-' + element + ' audio').play()
            },

            loadVideo(videourl) {
                this.currentVideoEmbed = videourl
                
                var vimeo = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
                if( vimeo.test(videourl) ) {
                    this.currentVideoEmbed = videourl.replace(vimeo, '//player.vimeo.com/video/$1');
                } else {
                    let youtubeID = '';
                    videourl = videourl.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                    if(videourl[2] !== undefined) {
                        youtubeID = videourl[2].split(/[^0-9a-z_\-]/i);
                        this.currentVideoEmbed = 'https://www.youtube.com/embed/' + youtubeID[0]
                    } else {
                        this.currentVideoEmbed = 'https://www.youtube.com/embed/' + videourl
                    }
                }
            },

            loadModal(event) {
                
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                if( event.target.href ) {
                    this.modalLink = event.target.href
                }
                else if( event.target.closest('a') ) {
                    if( isIE11 ) {
                        window.location.href = event.target.closest('a').href
                    } else {
                        this.modalLink = event.target.closest('a').href
                    }
                }
            },

            closeVideoModal() {
                this.currentVideoEmbed = ''
            },

            onModalClose() {
                this.modalLink = ''
            },

            openFaq(faqID, slug) {
                if( this.currentFaq == faqID ) {
                    document.querySelector('#a'+faqID).style.height=0
                    this.currentFaq = false
                    history.replaceState( null, null, '#' )
                } else {

                    if( this.currentFaq ) {
                        document.querySelector('#a'+this.currentFaq).style.height=0
                    }

                    this.currentFaq = faqID
                    document.querySelector('#a'+faqID).style.height = document.querySelector('#a'+faqID).scrollHeight + 'px';
                    history.replaceState( null, null, '#q'+faqID + '-' + slug )
                }
            },

            handleScroll() {
                const target = document.querySelectorAll('.parallax');

                let index = 0, length = target.length;
                for (index; index < length; index++) {
                    let pos = window.pageYOffset * target[index].dataset.rate;       
                    if(target[index].dataset.direction === 'vertical') {
                        target[index].setAttribute( 'style', 'transform: translate3d(0px,'+pos+'px, 0px) !important' );
                    } else {
                        let posX = window.pageYOffset * target[index].dataset.ratex;
                        let posY = window.pageYOffset * target[index].dataset.ratey;                           
                        target[index].setAttribute( 'style', 'transform: translate3d('+posX+'px, '+posY+'px, 0px) !important' );
                    }
                }
            },

            scrollToID(id) {
                const yOffset = -100;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
            },
        },

        created () {
            window.addEventListener('scroll', this.handleScroll, {passive: true});
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll, {passive: true});
        },

        mounted() {
            let match = window.location.hash.match(/^#q(\d+).*?/gi)
            if( match ) {
                let faqID = match[0].replace( '#q', '' )
                let slug  = window.location.hash.replace( match[0] + '-', '' )
                this.openFaq( faqID, slug )
                this.scrollToID( 'q'+faqID )
            }
        },
    });
};

// Execute async function
main().then((value) => {
    
});
